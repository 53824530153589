/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql, Link } from "gatsby";
import {
  FacebookShareButton,
  PinterestShareButton,
  TwitterShareButton
} from "react-share";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import Arrow from "~components/svg/Arrow";
import FacebookIcon from "~components/svg/FacebookIcon";
import PinterestIcon from "~components/svg/PinterestIcon";
import TwitterIcon from "~components/svg/TwitterIcon";

//

const ArticlePage = ({ data, location }) => {
  const { setActiveCategory } = useContext(AppContext);

  const shareable = typeof window !== `undefined`;

  const { allMarkdownRemark } = data;
  const { frontmatter } = data.markdownRemark;

  //

  const articles = [];
  const categories = [];

  let matched = false;
  let nextLink;
  let prevLink;

  allMarkdownRemark.edges.forEach(({ node }) => {
    articles.push(node);
  });

  articles.forEach((article, articleIndex) => {
    if (!categories.includes(article.frontmatter.category)) {
      categories.push(article.frontmatter.category);
    }

    if (!matched && article.frontmatter.title === frontmatter.title) {
      matched = true;

      if (articles?.[articleIndex + 1]) {
        nextLink = articles[articleIndex + 1].fields.slug;
      } else {
        nextLink = articles[0].fields.slug;
      }

      if (articles?.[articleIndex - 1]) {
        prevLink = articles[articleIndex - 1].fields.slug;
      } else {
        prevLink = articles[articles.length - 1].fields.slug;
      }
    }
  });

  //

  const sectionJSX = [];

  if (frontmatter?.sections?.[0]) {
    frontmatter.sections.forEach((section, sectionIndex) => {
      const sectionKey = `section-${sectionIndex}`;

      switch (section.type) {
        case `text`:
          sectionJSX.push(
            <div
              key={sectionKey}
              className="article-page__content__html w-full relative block whitespace-pre-wrap"
              dangerouslySetInnerHTML={{ __html: section.content }}
            ></div>
          );

          break;

        case `image`:
          sectionJSX.push(
            <div key={sectionKey} className="w-full relative block mt-12 mb-12">
              <figure className="w-full relative block">
                {(section?.image?.childImageSharp?.fluid && (
                  <Img
                    className="w-full relative block"
                    fluid={section.image.childImageSharp.fluid}
                    alt={section.imageAlt}
                  />
                )) || (
                  <>
                    {section?.image?.publicURL && (
                      <img
                        className="w-full relative block"
                        src={section.image.publicURL}
                        alt={section.imageAlt}
                      />
                    )}
                  </>
                )}

                {section?.imageCaption && (
                  <figcaption className="mt-2 caption">
                    {section.imageCaption}
                  </figcaption>
                )}
              </figure>
            </div>
          );
          break;

        case `2up`:
          sectionJSX.push(
            <div key={sectionKey} className="w-full relative flex xs:block">
              <div className="w-1/2 xs:w-full pr-4 xs:pr-0 whitespace-pre-wrap mb-8">
                {(section?.imageLeft?.childImageSharp?.fluid && (
                  <figure className="w-full relative block">
                    <Img
                      className="w-full relative block"
                      fluid={section.imageLeft.childImageSharp.fluid}
                      alt={section.imageAltLeft}
                    />

                    {section?.imageCaptionLeft && (
                      <figcaption className="mt-2 caption">
                        {section.imageCaptionLeft}
                      </figcaption>
                    )}
                  </figure>
                )) || (
                  <div
                    className="article-page__content__html w-full relative whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: section.contentLeft || ``
                    }}
                  ></div>
                )}
              </div>

              <div className="w-1/2 xs:w-full pr-4 xs:pr-0 whitespace-pre-wrap mb-8">
                {(section?.imageRight?.childImageSharp?.fluid && (
                  <figure className="w-full relative block">
                    <Img
                      className="w-full relative block"
                      fluid={section.imageRight.childImageSharp.fluid}
                      alt={section.imageAltRight}
                    />

                    {section?.imageCaptionRight && (
                      <figcaption className="mt-2 caption">
                        {section.imageCaptionRight}
                      </figcaption>
                    )}
                  </figure>
                )) || (
                  <div
                    className="article-page__content__html w-full relative whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{
                      __html: section.contentRight || ``
                    }}
                  ></div>
                )}
              </div>
            </div>
          );
          break;

        default:
          break;
      }
    });
  }

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="article-page w-full relative">
        <nav className="blog-page__filters w-full fixed top-0 right-0 left-0 z-20 bg-white border-b-black">
          <div className="grid">
            <ul className="grid-end-12 relative flex pt-4 pb-4">
              {categories.map(category => (
                <li className="mt-1 mr-5 b1">
                  <Link
                    to="/blog"
                    className="hover-underline"
                    onClick={() => setActiveCategory(category)}
                  >
                    {category}
                  </Link>
                </li>
              ))}

              <li className="mt-1 mr-5 b1">
                <Link
                  to="/blog"
                  className="hover-underline"
                  onClick={() => setActiveCategory(null)}
                >
                  All Stories
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <article className="w-full relative flex items-center justify-center">
          <div className="article-page__content animation-appear-down-slow animation-delay-3 mt-12 xs:mt-12">
            <h1 className="animation-appear-down-slow animation-delay-1 mt-24 xs:mt-20 mb-20 xs:mb-12 whitespace-pre-wrap f2">
              {frontmatter.articleTitle}
            </h1>

            {sectionJSX}
          </div>
        </article>

        <section className="grid pb-24">
          {shareable && (
            <ul className="grid-end-6 sm:grid-end-10 xs:grid-end-12 grid-start-4 sm:grid-start-2 xs:grid-start-1 flex items-center justify-start mt-20 xs:mt-12 -ml-4">
              <li className="w-12 h-12 relative flex items-center justify-center">
                <FacebookShareButton url={window.location.href}>
                  <FacebookIcon className="h-4" color="black" />
                </FacebookShareButton>
              </li>

              <li className="w-12 h-12 relative flex items-center justify-center">
                <TwitterShareButton url={window.location.href}>
                  <TwitterIcon className="h-4" color="black" />
                </TwitterShareButton>
              </li>

              <li className="w-12 h-12 relative flex items-center justify-center">
                {frontmatter?.image?.childImageSharp?.fluid?.src && (
                  <PinterestShareButton
                    media={frontmatter.image.childImageSharp.fluid.src}
                    url={window.location.href}
                  >
                    <PinterestIcon className="h-4" color="black" />
                  </PinterestShareButton>
                )}
              </li>
            </ul>
          )}

          <div className="grid-end-6 sm:grid-end-10 xs:grid-end-12 grid-start-4 sm:grid-start-2 xs:grid-start-1 relative flex items-center justify-between mt-16 xs:mt-12">
            <Link
              to={prevLink}
              className="article-page__arrow article-page__arrow--left relative block pt-3 pb-3"
            >
              <div className="relative flex items-center justify-start">
                <Arrow className="h-3 mr-2 pointer-events-none" direction="<" />
                <span className="pointer-events-none">previous</span>
              </div>
            </Link>

            <Link
              to={nextLink}
              className="article-page__arrow article-page__arrow--right relative block pt-3 pb-3"
            >
              <div className="relative flex items-center justify-end">
                <span className="pointer-events-none">next</span>
                <Arrow className="h-3 ml-2 pointer-events-none" />
              </div>
            </Link>
          </div>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default ArticlePage;

export const query = graphql`
  query ArticlePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        articleTitle
        image {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          publicURL
        }
        sections {
          type
          content
          image {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageAlt
          imageCaption
          contentLeft
          imageLeft {
            childImageSharp {
              fluid(maxWidth: 768, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageAltLeft
          imageCaptionLeft
          contentRight
          imageRight {
            childImageSharp {
              fluid(maxWidth: 768, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
            publicURL
          }
          imageAltRight
          imageCaptionRight
        }
        category
        seoDescription
        seoKeywords
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "article-page" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
            articleTitle
          }
        }
      }
    }
  }
`;
