import React from "react";
import PropTypes from "prop-types";

const TwitterIcon = ({ className, color }) => (
  <svg className={className} viewBox="0 0 20 16" fill="none">
    <path
      d="M6.2002 16C13.6002 16 17.7002 9.8 17.7002 4.5C17.7002 4.3 17.7002 4.2 17.7002 4C18.5002 3.4 19.2002 2.7 19.7002 1.9C19.0002 2.2 18.2002 2.4 17.3002 2.5C18.1002 2 18.8002 1.2 19.1002 0.3C18.3002 0.7 17.4002 1.1 16.5002 1.3C15.8002 0.5 14.7002 0 13.5002 0C11.3002 0 9.5002 1.8 9.5002 4C9.5002 4.3 9.5002 4.6 9.6002 4.9C6.3002 4.8 3.3002 3.1 1.3002 0.7C1.0002 1.3 0.800196 2 0.800196 2.7C0.800196 4.1 1.5002 5.4 2.6002 6.1C2.0002 6.1 1.3002 5.9 0.800196 5.6C0.800196 7.6 2.2002 9.2 4.1002 9.5C3.8002 9.6 3.5002 9.7 3.1002 9.7C2.9002 9.7 2.6002 9.7 2.3002 9.6C2.8002 11.2 4.3002 12.4 6.1002 12.4C4.7002 13.5 2.9002 14.1 1.1002 14.1C0.800196 14.1 0.500195 14.1 0.200195 14.1C1.9002 15.4 4.0002 16 6.2002 16Z"
      fill={color}
    />
  </svg>
);

TwitterIcon.defaultProps = {
  className: ``,
  color: `white`
};

TwitterIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default TwitterIcon;
