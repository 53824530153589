import React from "react";
import PropTypes from "prop-types";

const PinterestIcon = ({ className, color }) => (
  <svg className={className} viewBox="0 0 16 21" fill="none">
    <path
      d="M15.7996 6.39997C15.7996 10.9 13.1996 14.3 9.49961 14.3C8.19961 14.3 6.89961 13.6 6.59961 12.9C6.59961 12.9 5.89961 15.6 5.69961 16.1C5.09961 18 3.69961 19.8 3.49961 20C3.39961 20.1 3.19961 20.1 3.19961 20C3.19961 19.9 2.89961 17.4 3.19961 15.5C3.29961 14.6 4.59961 9.19997 4.59961 9.19997C4.59961 9.19997 4.29961 8.49997 4.29961 7.49997C4.29961 5.89997 5.49961 4.49997 6.49961 4.49997C7.69961 4.49997 8.09961 5.19997 8.09961 6.19997C8.09961 7.39997 7.39961 8.89997 6.89961 10.4C6.59961 11.7 7.49961 12.6 8.59961 12.6C10.7996 12.6 12.1996 9.69997 12.1996 6.59997C12.1996 3.99997 10.5996 2.09997 7.29961 2.09997C4.59961 2.09997 2.29961 4.79997 2.29961 7.69997C2.29961 8.89997 2.59961 9.29997 2.99961 9.99997C3.09961 10.1 3.09961 10.3 3.09961 10.7C3.09961 10.8 2.99961 11.4 2.99961 11.6C2.89961 11.9 2.69961 11.9 2.39961 11.9C0.799609 11.2 0.0996094 9.29997 0.0996094 7.39997C0.0996094 4.19997 2.79961 0.19997 8.49961 0.19997C12.8996 -0.30003 15.7996 2.89997 15.7996 6.39997Z"
      fill={color}
    />
  </svg>
);

PinterestIcon.defaultProps = {
  className: ``,
  color: `white`
};

PinterestIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default PinterestIcon;
