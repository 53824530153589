import React from "react";
import PropTypes from "prop-types";

const Arrow = ({ className, color, direction }) => {
  if (direction === `>`) {
    className = `${className} invert-x`;
  }

  return (
    <svg className={className} viewBox="0 0 19 8" fill="none">
      <path
        d="M0.646446 3.64644C0.451185 3.84171 0.451185 4.15829 0.646446 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82842L1.70711 4L4.53553 1.17157C4.7308 0.976309 4.7308 0.659726 4.53553 0.464464C4.34027 0.269202 4.02369 0.269202 3.82843 0.464464L0.646446 3.64644ZM19 3.5L1 3.5L1 4.5L19 4.5L19 3.5Z"
        fill={color}
      />
    </svg>
  );
};

Arrow.defaultProps = {
  className: ``,
  color: `black`,
  direction: `>`
};

Arrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  direction: PropTypes.string
};

export default Arrow;
