import React from "react";
import PropTypes from "prop-types";

const FacebookIcon = ({ className, color }) => (
  <svg className={className} viewBox="0 0 12 20" fill="none">
    <path
      d="M7.4998 20V10.9H10.5998L11.0998 7.3H7.5998V5.1C7.5998 4.1 7.8998 3.4 9.3998 3.4H11.1998V0.1C10.8998 0.1 9.7998 0 8.4998 0C5.7998 0 3.8998 1.7 3.8998 4.7V7.3H0.799805V10.9H3.8998V20H7.4998Z"
      fill={color}
    />
  </svg>
);

FacebookIcon.defaultProps = {
  className: ``,
  color: `white`
};

FacebookIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default FacebookIcon;
